import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { login, logoutUserr, useAuth } from "../../redux/slices/authSlice";
import CustomeButton from "../../components/Custom/Forms/CustomeButton/CustomeButton";
import { loggInUser, responseToaster } from "../../helperFunctions";

const LogginUser = (props) => {
  const { open } = props;
  const dispatch = useDispatch();
  const { auth } = useAuth();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const Mark_As_Failed = async () => {
    try {
      setIsButtonLoading(true);
      const payload = {
        id: auth?.user?.user_id,
        is_admin: auth?.user?.is_Admin,
      };
      let response = await dispatch(logoutUserr(payload)).unwrap();
      responseToaster(response);
      if (!!response) {
        loggInUser(dispatch, false);
      }
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onClose = () => {
    try {
      loggInUser(dispatch, false);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Modal isOpen={open} centered={true}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">Device Logout Alert</h3>
      </div>
      <div className="modal-body">
        <div>
          <p>Are you sure you want to log out from another device?</p>
        </div>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-end button-space">
          <Button
            className="graybutton"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <CustomeButton
            className="submitbutton"
            onClick={Mark_As_Failed}
            isButtonLoading={isButtonLoading}
          >
            Yes
          </CustomeButton>
        </div>
      </div>
    </Modal>
  );
};

export default LogginUser;
