import axios from "axios";
import { baseUrl } from "../api/baseUrl";
import {
  getLocalData,
  loggInUser,
  responseToaster,
  setLocalData,
  verifyingUser,
} from "../../helperFunctions";
import { useClearData, useTwofactorAuth } from "../../utility/hooks";
import { store } from "../store";
import { setHasResetPwd, setUser } from "../slices/authSlice";

export const apiInstance = axios.create({
  baseURL: baseUrl,
});
apiInstance.interceptors.request.use(
  function (config) {
    const token = getLocalData("token");
    const faToken = getLocalData("2faToken");
    const b = getLocalData("b");

    if (token) {
      config.headers.Authorization = `Bearer ${token.token}`;
    }
    if (faToken) {
      config.headers["X-2FA-TOKEN"] = faToken;
    }
    config.headers.location = window.location.pathname;
    config.headers.b = b?.visitorId || "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
apiInstance.interceptors.response.use(
  function (response) {
    if (response?.data?.message === "Password reset successfully.") {
      store.dispatch(setHasResetPwd(response?.data))
      setLocalData("mailPwd", response?.data)
    }
    return response.data;
  },
  function (error) {
    if (error?.response?.data) {
      if (error?.response?.data?.status === false) {
        if (error?.response?.data?.message === "You are already logged in on another device.") {
          loggInUser(store.dispatch, true);
          store.dispatch(setUser(error?.response?.data));
          return;
        }
      }
      if (error?.response?.data?.status === false) {
        if (error?.response?.data?.message === "Two-factor authentication required.") {
          verifyingUser(store.dispatch, true);
          return;
        }
        if (error?.response?.data?.message === "Please Enable Two-factor authentication.") {
          verifyingUser(store.dispatch, false);
          return;
        }
      } else {
        verifyingUser(store.dispatch, false);
      }
    }
    if (error?.code === "ERR_NETWORK") {
      responseToaster({
        status: false,
        message: "Please check internet connection and try again!",
      });
    } else {
      const token = getLocalData("token");
      if (error?.response?.data?.is_block === 1) {
        setLocalData("is_block", true);
        return Promise.reject(error);
      } else {
        setLocalData("is_block", false);
      }

      if (
        token ||
        error?.request?.responseURL?.split("/")?.at(-1) === "login"
      ) {
        responseToaster(error?.response?.data);
      }
    }

    if (
      error?.response?.data?.message === "Unauthenticated." ||
      error?.response?.data?.message === "Unauthorized"
    ) {
      useClearData();
    }

    return Promise.reject(error);
  }
);
