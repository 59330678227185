import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import { useRole } from "../../../redux/slices/authSlice";
import {
  bankAnalyticsGetForDate,
  bankAnalyticsGetForDay,
} from "../../../redux/slices/BankAnalyticsSlice";
import { FILTER } from "../../../constant";
import { dateFormatter, getCurrentMonthDates, responseToaster, seperator } from "../../../helperFunctions";
import DatePicker from "../../../components/Custom/Forms/DatePicker/DatePicker";
import { useForm } from "react-hook-form";
import BarChart from "../../../components/Custom/Elements/Chart/BarChart";
import Loader from "../../../components/Custom/Loader";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const now = new Date();
const year = now.getFullYear();
const month = now.getMonth();
const daysInMonth = new Date(year, month + 1, 0).getDate();

const BankAnalytics = () => {
  const { startOfMonth, endOfMonth } = getCurrentMonthDates();
  const [selectedRange, setSelectedRange] = useState([
    startOfMonth,
    endOfMonth,
  ]);

  const initialFilter = {
    isFilter: false,
    date: [startOfMonth, endOfMonth],
  };
  const [chartDate, setChartDate] = useState([startOfMonth, endOfMonth]);
  const tabsData = [{ title: "Chart" }, { title: "Table" }];
  const [isLoading, setIsLoading] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [filterForDay, setFilterForDay] = useState({ ...initialFilter });
  const [filterForDate, setFilterForDate] = useState({ ...initialFilter });
  const [currentTab, setCurrentTab] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const data = useSelector((state) => state?.bankAnalytics);

  const role = useRole("Bank Analytics");
  const { control, handleSubmit, getValues, reset, values } =
    useForm({
      defaultValues: initialFilter,
    });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const columns = [
    {
      title: "Bank Name",
      name: "bank_name",
    },
    {
      title: "active days/total days",
      name: "total_active_days",
      Cell: ((data) => {
        return <span>{`${data?.total_active_days || 0}/${data?.total_days || 0}`}</span>
      })
    },
    {
      title: "transaction count",
      name: "transaction_count",
    },
    {
      title: "payment amount",
      name: "payment_amount",
      Cell: ((data) => {
        return <span>{seperator(data?.payment_amount)}</span>
      })
    },
  ];

  const onGetBankAnalytics = async (type) => {
    try {
      setIsLoading(true);
      const { isFilter, ...rest } = filterForDay;
      const { isFilter: isFilterForDate, date } = filterForDate;
      const payload = {
        startDate: dateFormatter(selectedRange?.[0], "start"),
        endDate: dateFormatter(selectedRange?.[1], "end", selectedRange?.[0]),
        type,
      };
      const payloadForTable = {
        ...values,
        startDate: dateFormatter(date?.[0], "start"),
        endDate: dateFormatter(date?.[1], "end", date?.[0]),
        type,
      };
      const res =
        type === "DAY"
          ? await dispatch(bankAnalyticsGetForDay(payload)).unwrap()
          : await dispatch(bankAnalyticsGetForDate(payloadForTable)).unwrap();

      if (isFilter || isFilterForDate) {
        responseToaster(res);
      }
      setChartDate(selectedRange);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (role.includes("bankAnalytics-list") && currentTab === 0) {
      onGetBankAnalytics("DAY");
    }
  }, [filterForDay, currentTab]);

  useEffect(() => {
    if (role.includes("bankAnalytics-list") && currentTab === 1) {
      onGetBankAnalytics("DATE");
    }
  }, [filterForDate, currentTab]);

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const onSubmitChart = () => {
    const values = getValues();
    if (currentTab === 0) {
      setFilterForDay({ ...filterForDay, ...values, isFilter: true });
    }
    if (currentTab === 1) {
      setFilterForDate({ ...filterForDate, ...values, isFilter: true });
    }
  };

  if (!role.includes("bankAnalytics-list")) {
    return;
  }
  const handleDateChange = ([newStartDate, newEndDate]) => {
    if (!newStartDate && !newEndDate) {
      setSelectedRange([null, null]);
      return;
    }
  
    const clonesNewSdate = newStartDate || new Date();
    const startOfMonth = new Date(
      clonesNewSdate.getFullYear(),
      clonesNewSdate.getMonth(),
      1
    );
    let endOfMonth = new Date(
      clonesNewSdate.getFullYear(),
      clonesNewSdate.getMonth() + 1,
      0
    );
    const customEndDate = new Date();
    if (endOfMonth > customEndDate) {
      endOfMonth = customEndDate;
    }
    setSelectedRange([startOfMonth, endOfMonth]);
  };

  const generateDatasets = (banks) => {
    const datasets = [];
    banks.forEach((bank, index) => {
      bank.activity_periods.forEach((period) => {
        const adjustedStartDay = period.start_day;
        const adjustedEndDay = period.end_day + 1;

        const days = adjustedEndDay - adjustedStartDay;
        const data = new Array(banks.length).fill(0);
        data[index] = days;

        const dataset = {
          backgroundColor:
            period.status === "inactive-blank"
              ? "#f1f1f1"
              : period.status === "active"
                ? "#4ba83b"
                : "#ff9494",
          data: data,
          stack: `Stack 0`,
          borderWidth: 1,
          tooltip: "sdfs",
          borderColor: "#f4f2f2",
          borderWidth: {
            top: 1,
            left: 0,
            right: 0,
            bottom: 1,
          },
          bank_name: bank?.name,
        };

        if (period.status !== "inactive-blank") {
          dataset.label = `${bank.name} - ${period.status.charAt(0).toUpperCase() + period.status.slice(1)
            } Days ${period.start_day === 0.5 ? 1 : Math.floor(period.start_day)
            }-${Math.floor(period.end_day)}`;
        }

        datasets.push(dataset);
      });
    });

    return datasets;
  };

  const fillMissingDays = (periods, maxDays = 30, bankName) => {
    const filledPeriods = [];
    let lastEndDay = -0.5;

    periods?.details?.forEach((period) => {
      if (period.start_day >= lastEndDay + 1) {
        filledPeriods.push({
          status: "inactive-blank",
          start_day: lastEndDay + 1,
          end_day: period.start_day - 1,
          total_active_days: periods.total_active_days,
          total_days: period.total_days,
        });
      }

      filledPeriods.push(period);
      lastEndDay = period.end_day;
    });

    return filledPeriods;
  };

  const preprocessData = (data, maxDays = 30) => {
    return Object.keys?.(data || {}).map((bankName) => ({
      name: bankName,
      activity_periods: fillMissingDays(data[bankName], maxDays, bankName),
    }));
  };
  const convertedData = {
    banks: preprocessData(
      data?.dataForDay?.data || {},
      chartDate?.[1]?.getDate()
    ),
  };

  const chartData = {
    labels: convertedData?.banks?.map?.((bank) => bank?.name),
    datasets: generateDatasets(convertedData?.banks)?.map?.((dataset) => ({
      ...dataset,
    })),
  };

  const totalizer = {
    id: "totalizer",
    beforeUpdate: (chart) => {
      let totals = {};
      let utmost = 0;

      chart.data.datasets.forEach((dataset, datasetIndex) => {
        if (chart.isDatasetVisible(datasetIndex)) {
          utmost = datasetIndex;

          const bankName = dataset.bank_name;
          const bankData = data?.dataForDay?.data?.[bankName];
          if (bankData) {
            const { total_active_days, total_days } = bankData;
            const ratio = total_active_days / total_days;

            totals[bankName] = {
              ratio: ratio,
              total_active_days: total_active_days,
              total_days: total_days,
            };
          }
        }
      });

      chart.$totalizer = {
        totals: totals,
        utmost: utmost,
      };
    },
  };
  const dataLength = Object.keys?.(data?.dataForDay?.data || {})?.length;
  const option = {
    responsive: true,
    aspectRatio: 12 / (2 + (dataLength / 5)),
    // aspectRatio: windowWidth >= 560 ? 2.5 / 2 : windowWidth < 768 ? 2/2 : 1 / 2,
    layout: {
      padding: { right: 25 },
    },
    plugins: {
      datalabels: {
        formatter: function (value, ctx) {
          const totalizer = ctx.chart.$totalizer;
          const temp = Object.values?.(totalizer?.totals);
          const getRatio = temp?.[ctx.dataIndex];
          if (!getRatio) return value;

          const total = `${getRatio.total_active_days}/${getRatio.total_days}`;
          return total;
        },
        display: function (ctx) {
          return ctx.datasetIndex === ctx.chart.$totalizer.utmost;
        },
        color: "#000",
        anchor: "end",
        align: "end",
      },
      title: {
        display: true,
      },
      legend: {
        display: false,
      },
    },
    barPercentage: 0,
    categoryPercentage: 1,
    barThickness: windowWidth >= 1231 ? 25 : windowWidth < 768 ? 10 : 12,
    indexAxis: "y",
    scales: {
      x: {
        min: 1,
        max: chartDate?.[1]?.getDate(),
        stacked: true,
        offset: true,
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          stepSize: 1,
          min: 0,
          max: daysInMonth,
        },
      },
      y: {
        grid: {
          display: false,
          size: 10,
        },
      },
    },
  };

  return (
    <>
      {role.includes("bankAnalytics-list") ? (
        <>
          <div className="support-log-main-div">
            <Card>
              <CardHeader className="flex-column  align-items-start">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <div>
                    <h4 className="main-title">
                      <b>Bank Analytics</b>
                    </h4>
                  </div>
                  <div
                    className="d-flex my-xl-auto right-content align-items-end button-space"
                    style={{ flexWrap: "wrap" }}
                  >
                    {tabsData?.map((tab, index) => {
                      return (
                        <Button
                          className={
                            currentTab === index ? "btn-primary" : "filterbtn"
                          }
                          color={currentTab === index ? "btn-primary" : ""}
                          onClick={() => {
                            setCurrentTab(index);
                            reset();
                            setIsLoading(false);
                            setFilterForDay({ ...initialFilter });
                            setFilterForDate({ ...initialFilter });
                            setSelectedRange([startOfMonth, endOfMonth]);
                            // setFilter({ ...TRA_FILTER });
                          }}
                        >
                          {tab.title}
                        </Button>
                      );
                    })}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  className="d-flex align-items-center mt-md-0 mt-1"
                  style={{ flexWrap: "wrap" }}
                  onSubmit={handleSubmit(onSubmitChart)}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-end inputgap">
                        {currentTab === 0 ? (
                          <DatePicker
                            selected={selectedRange[0]}
                            startDate={selectedRange[0]}
                            endDate={selectedRange[1]}
                            maxDate={new Date()}
                            selectsRange
                            isKeyDown={false}
                            onChange={handleDateChange}
                            showMonthYearPicker
                          />
                        ) : (
                          <ControlledDatePicker
                            name="date"
                            placeholder="Select Date"
                            maxDate={new Date()}
                            selectsRange
                            control={control}
                          />
                        )}
                        <Button color="primary" type="submit">
                          Apply
                        </Button>
                        <Button
                          type="button"
                          color="danger "
                          onClick={() => {
                            reset();
                            setSelectedRange([startOfMonth, endOfMonth]);
                            if (currentTab === 0) {
                              setFilterForDay({ ...initialFilter });
                            }
                            if (currentTab === 1) {
                              setFilterForDate({ ...initialFilter });
                            }
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
              {currentTab === 0 ? (
                <div className="chartcss">
                  {isLoading ? (
                    <div
                      style={{ minHeight: "400px" }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Loader />{" "}
                    </div>
                  ) : Object.keys?.(data?.dataForDay?.data || {})?.length ? (
                    <BarChart
                      options={option}
                      data={chartData}
                      isLoading={isLoading}
                      plugins={[totalizer, ChartDataLabels]}
                      showDataLabels={true}
                    />
                  ) : (
                    <div
                      style={{ minHeight: "400px" }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      No data found
                    </div>
                  )}
                </div>
              ) : (
                <Table
                  columns={columns}
                  isLoading={isLoading}
                  data={data?.dataForDate?.data}
                  isData={data?.dataForDate?.data?.length}
                  filterColumns={filterColumns}
                  isExpandable={false}
                  onColumnsChange={(columns) => {
                    setFilterColumns(columns);
                  }}
                >
                  {data?.dataForDate?.data
                    ?.map((item) => {
                      return (
                        <TableRow
                          columns={columns}
                          item={item}
                          filterColumns={filterColumns}
                          isExpandable={false}
                        />
                      );
                    })}
                </Table>
              )}
            </Card>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankAnalytics;
